<template>
  <div style="width: inherit; height: inherit">
    <div
      ref="echart"
      v-resize="onResize"
      style="width: inherit; height: inherit"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import ApiService from '../../../services/api.service';
import { createSeriesOption } from './lib/transformer';

import { createCategoryQueryParams } from '../../../lib/query-creator';

export default {
  components: {},
  props: ['dataset', 'payload'],
  data() {
    return {
      chart: null,
    };
  },
  computed: {},
  async mounted() {
    this.init();
    // alert('loaded');
    this.reload();
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      // this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {},
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async reload() {
      try {
        this.$store.commit('mSetLoading', true);
        await this.loadData();
      } catch (error) {}
      this.$store.commit('mSetLoading', false);
    },
    async loadData() {
      if (this.dataset && this.payload) {
        console.log('this.dataset', this.dataset);
        console.log('this.payload', this.payload);

        // const sql = createSeriesQuery(this.dataset);
        const response = await ApiService.post('/feature_importance/', this.payload);
        const params = {
          valueColumn: 'value',
          labelColumn: 'value',
        };
        const data = response.data;
        const option = createSeriesOption(data);
        // console.log('option', option);
        this.draw(option);
      }
    },
  },
};
</script>
