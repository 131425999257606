export const createSeriesOption = (data) => {
  const title = data.description;
  const x = data.feature;
  const y = data.value;

  const option = {
    title: {
      text: title,
    },
    xAxis: {
      type: 'category',
      data: x,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: y,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)',
        },
      },
    ],
  };

  return option;
};
